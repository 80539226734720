<template>
	<div>
		<b-modal
			v-model="showLogoPopup"
			size="lg"
			centered
			hide-footer
			@hide="() => (showLogoPopup = false)"
		>
			<img :src="applicationLogo" class="card-img-top" alt="Application Logo" />
		</b-modal>
		<b-modal
			v-model="showVendorInfo"
			size="lg"
			centered
			hide-footer
			@hide="hidePopup"
		>
			<ApplicationInputs :data="selectedVendor" />
		</b-modal>
		<b-card>
			<div class="custom-search">
				<b-form-group>
					<div class="d-flex align-items-center">
						<b-form-input
							v-model="searchTerm"
							placeholder="Search"
							type="text"
							class="d-inline-block"
						/>
					</div>
				</b-form-group>
			</div>
			<!-- table -->
			<b-table
				:fields="columns"
				:items="applicationsList"
				class="shadow-sm rounded"
				small
				responsive
				hover
				bordered
				:busy="isTableBusy"
				empty-text="No matching records found"
				@row-clicked="onRowClick"
			>
				<template #table-busy>
					<div class="text-center text-danger my-5">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<template #cell(action)="data">
					<b-button
						v-b-tooltip.hover.bottom="'Approve'"
						variant="flat-success"
						class="btn-icon"
						@click="onApprove(data.item.id)"
					>
						<feather-icon size="16" icon="CheckSquareIcon" />
					</b-button>
					<b-button
						v-b-tooltip.hover.bottom="'Reject'"
						class="btn-icon"
						variant="flat-danger"
						@click="onReject(data.item.id)"
					>
						<feather-icon size="16" icon="XSquareIcon" />
					</b-button>
				</template>
			</b-table>
		</b-card>
	</div>
</template>

<script>
import {
	BFormGroup,
	BFormInput,
	BTable,
	BButton,
	VBTooltip,
	BCard,
	BSpinner,
} from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Swal from 'sweetalert2';
import store from '@/store/index';
import ApplicationInputs from './ApplicationInputs.vue';

export default {
	components: {
		BFormGroup,
		BFormInput,
		ApplicationInputs,
		BTable,
		BButton,
		BCard,
		BSpinner,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
	},

	directives: {
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			applicationsList: null,
			isTableBusy: true,
			pageLength: 10,
			action: null,
			selectedVendor: {},
			showVendorInfo: false,
			showLogoPopup: false,
			applicationLogo: null,
			columns: [
				{
					label: 'ID',
					key: 'id',
				},
				{
					label: 'Brand Name',
					key: 'brandName',
				},
				{
					label: 'Contact Person',
					key: 'contactPerson',
				},
				{
					label: 'Legal Address',
					key: 'legalAddress',
				},
				{
					label: 'Mobile',
					key: 'contactPersonMobile',
				},
				{
					label: 'Action',
					key: 'action',
				},
			],
			searchTerm: '',
		};
	},
	computed: {
		...mapState('vendorManagement', ['applications']),
		tableTheme() {
			if (store.state.appConfig.layout.skin === 'dark') {
				return 'nocturnal';
			}
			return '';
		},
	},
	created() {
		this.getApplications({ skip: 0, take: 10, searchKey: '' }).then(() => {
			this.applicationsList = this.applications.data.applications;
			this.isTableBusy = false;
		});
	},
	methods: {
		...mapActions('vendorManagement', [
			'setVendorApplicationStatus',
			'getApplications',
		]),
		showLogo(logo) {
			this.showLogoPopup = true;
			this.applicationLogo = logo;
		},
		hidePopup() {
			this.showVendorInfo = false;
		},
		onRowClick(item) {
			this.selectedVendor = item;
			this.showVendorInfo = true;
		},
		onReject(applicationId) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, Reject it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.setVendorApplicationStatus({
						id: applicationId,
						status: 2,
					}).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Application successfully rejected.',
								icon: 'FileIcon',
								variant: 'success',
							},
						});
						this.getApplications({ skip: 0, take: 10, searchKey: '' });
					});
				}
			});
		},
		onApprove(applicationId) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, Approve it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.setVendorApplicationStatus({
						id: applicationId,
						status: 3,
					}).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Application successfully approved.',
								icon: 'FileIcon',
								variant: 'success',
							},
						});
						this.getApplications({ skip: 0, take: 10, searchKey: '' });
					});
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.buttonsWrapper {
	display: flex;
	justify-content: space-between;
	@media (max-width: 600px) {
		flex-direction: column;
	}
}
.custom-search {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
}
.form-group {
	@media (max-width: 600px) {
		width: 100%;
	}
}
.pointer {
	cursor: pointer;
}
</style>
